import React, { Component } from "react";

// import "./new_slider.scss";
import image_1 from "../images/dental_1.jpeg";
import image_2 from "../images/dental_2.jpeg";
import image_3 from "../images/dental_3.jpeg";
import image_4 from "../images/dental_4.jpeg";
import image_5 from "../images/dental_5.jpeg";
import image_6 from "../images/dental_6.jpg";

class Image_Grid extends Component {
  state = { modalIsOpen: false };
  toggleModal = () => {
    this.setState((state) => ({ modalIsOpen: !state.modalIsOpen }));
  };
  render() {
    const { modalIsOpen } = this.state;

    return (
      <div>
        <hr style={{ border: "1px solid black", width: "50%" }} />
        <div
          className="gridRow"
          style={{ marginBottom: "15vh", marginTop: "10vh" }}
        >
          <div className="gridColumn hideElement">
            <img
              src={image_1}
              style={{ width: "100%", filter: "grayscale(100%)" }}
              alt=""
            />
            <img
              src={image_6}
              style={{ width: "100%", filter: "grayscale(100%)" }}
              alt=""
            />
          </div>
          <div className="gridColumn" style={{ marginBottom: "5vh" }}>
            <img
              src={image_2}
              style={{
                width: "100%",
                // height: "35vh",
                objectFit: "cover",
                filter: "grayscale(100%)",
              }}
              alt=""
            />
            <img
              src={image_3}
              style={{
                width: "100%",
                // height: "45vh",
                objectFit: "cover",
                filter: "grayscale(100%)",
              }}
              alt=""
            />
          </div>

          <div
            className="gridColumn hideLastElement"
            style={{ marginTop: "0" }}
          >
            <img
              src={image_5}
              style={{ width: "100%", filter: "grayscale(100%)" }}
              alt=""
            />
            <img
              src={image_4}
              style={{ width: "100%", filter: "grayscale(100%)" }}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Image_Grid;
