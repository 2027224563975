import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import App from "./pages/App";
import Covid from "./pages/covid_page";
import history from "./history";

class AppRouter extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/dentalLab" component={App} />
          <Route path="/covid" component={Covid} />
          <Route path="/dentalLab/covid" component={Covid} />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
