import React, { Component } from "react";
import image_url from "../images/image_3.jpeg";

class About extends Component {
  render() {
    return (
      <div id="about" style={{ marginBottom: "5vh" }}>
        <div style={{ background: "black", height: "100px" }}></div>
        <div style={{ background: "black", height: "70px" }}>
          <span className="about-header">
            <b>About Us</b>
          </span>
        </div>
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <img
                  src={image_url}
                  style={{
                    width: "100%",
                    height: "80%",
                    borderRadius: "15px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <p className="about-text">
                  <span className="first-letter">H</span>ere at Xpress Custom
                  Dental Lab, our technicians have over 20 years of experience
                  and we pride ourselves by being a service driven and customer
                  oriented organization. We take pride in each case, and
                  coordinate job specific requirements to provide our client
                  with personalized service. Regardless of your needs, our
                  passion is to provide quality work, at your demand. <br />
                  We take pride in assisting in case design, preparation
                  guidelines, technology selection, and treatment approval to
                  assist in the perfect end result of aesthetics, contour, and
                  fit. Our lab and staff continues to move with the times, and
                  remain up to date on the latest technologies and methods to
                  produce you and your patients with removable restorations that
                  function well, fit comfortably, and enhance the patient’s
                  appearance, all while creating your perfect smile.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
