import React, { Component } from "react";
import service_1 from "../images/dental_5.jpeg";

class Terms_of_Service extends Component {
  render() {
    return (
      <>
        <div
          id="services"
          style={{ background: "black", height: "100px" }}
        ></div>
        <div style={{ background: "black", height: "70px" }}>
          <span className="about-header">
            <b>Terms of Service</b>
          </span>
        </div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "15vh",
            marginTop: "5vh",
          }}
        >
          <table className="tableContent" cellSpacing="0" cellPadding="0">
            <tbody>
              <tr>
                <td>
                  <img
                    style={{
                      verticalAlign: "bottom",
                      width: "100%",
                      height: "59%",
                      objectFit: "cover",
                    }}
                    src={service_1}
                    alt=""
                  />
                </td>
                <td style={{ background: "#fcba03" }}>
                  <div
                    style={{
                      maxWidth: "100vh",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.2rem",
                        color: "#414042",
                        padding: "4vh",
                        textAlign: "left",
                      }}
                    >
                      1.: Any repairs within (1) year, 100% replacement
                      <hr style={{ border: "1px solid black" }} />
                      2.: Remake Policy: Client has the right to inspect case(s)
                      prior to acceptance provided that notice of
                      non-acceptance, or non conformance of the case(s) shall be
                      communicated to XCDL not to exceed 10 business days after
                      receipt
                      <hr style={{ border: "1px solid black" }} />
                      3.: Payment Terms: A monthly statement will be available
                      to all clients via, mail, email, or portal based on their
                      initial request. Full payment is required within 30 days
                      or there will be a 3% late penalty applied to all past due
                      balances. If payment arrangements are required, clients
                      are urged to contact the lab within those 30 days to
                      prevent the late penalty.
                      <hr style={{ border: "1px solid black" }} />
                      4.: No warranty on repairs that exceeded the remake
                      policy.
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="tableContent_2">
            <tbody>
              <tr>
                <td>
                  <img src={service_1} style={{ width: "100%" }} alt="" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    background: "#fcba03",
                  }}
                >
                  <p style={{ fontSize: "1.2rem" }}>
                    1.: Any repairs within (1) year, 100% replacement
                    <br />
                    <br />
                    2.: Remake Policy: Client has the right to inspect case(s)
                    prior to acceptance provided that notice of non-acceptance,
                    or non conformance of the case(s) shall be communicated to
                    XCDL not to exceed 10 business days after receipt
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ background: "black", color: "white" }}>
                  <p style={{ fontSize: "1.1rem", marginTop: "10px" }}>
                    3.: Payment Terms: A monthly statement will be available to
                    all clients via, mail, email, or portal based on their
                    initial request. Full payment is required within 30 days or
                    there will be a 3% late penalty applied to all past due
                    balances. If payment arrangements are required , clients are
                    urged to contact the lab within those 30 days to prevent the
                    late penalty.
                    <br />
                    <br />
                    4.: No warranty on repairs that exceeded the remake policy.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default Terms_of_Service;
