import React, { Component } from "react";
import logo from "../images/logo.png";

class Footer extends Component {
  render() {
    return (
      <div id="contact">
        <footer
          className="page-footer font-small teal pt-4"
          style={{ background: "black", color: "white" }}
        >
          <div className="container text-center text-md-left">
            <div className="row">
              <div className="col-md-4 mt-md-0 mt-3">
                <img src={logo} style={{ marginBottom: "5vh", width: "60%" }} />
                <h5 className="text-uppercase font-weight-bold">
                  CALL US TODAY TO EXPERIENCE THE DIFFERENCE, AND CREATE YOUR
                  PERFECT SMILE.
                </h5>
                <hr style={{ border: "1px solid white" }} />
              </div>
              <div className="col-md-4 mt-md-0 mt-3">
                <h5 className="text-uppercase font-weight-bold">Contact</h5>
                <p className="font-for-span" style={{ fontSize: "larger" }}>
                  7600 Hanover Pkwy Ste 202, <br />
                  Greenbelt MD 20770
                  <br /> Monday - Friday: 8am - 5pm
                </p>
              </div>

              <hr className="clearfix w-100 d-md-none pb-3" />

              <div className="col-md-4 mb-md-0 mb-3">
                <p className="font-for-span" style={{ fontSize: "larger" }}>
                  Phone: 301-477-4959
                  <br />
                  Fax: 240-391-6029
                  <br />
                  Email:{" "}
                  <a className="email-tag" href="mailto: Xpress@xcdlab.com">
                    Xpress@xcdlab.com
                  </a>
                  <br /> Instagram: xcdlab
                  <br />
                  Facebook: XCDLab
                </p>
              </div>
            </div>
          </div>

          <div className="footer-copyright text-center py-3">
            © 2020 Copyright:
            <a href="#" style={{ color: "white" }}>
              {" "}
              Xpress Custom Dental Lab
            </a>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
