import React, { Component } from "react";
import smiles from "../images/smiles.jpg";
import office_hours from "../images/office_image.jpg";
import services from "../images/brushes.jpeg";

class Benefits extends Component {
  render() {
    return (
      <>
        <div style={{ marginTop: "5vh" }}>
          <div className="container">
            <h1 className="benefits-text">
              <b>Benefits</b>
            </h1>
            <div
              className="row"
              style={{
                textAlign: "center",
                margin: "-4vh auto",
                width: "75%",
              }}
            >
              <br />
              <div
                className="col"
                style={{ marginTop: "5vh", marginBottom: "5vh" }}
              >
                <div
                  style={{
                    width: "200px",
                    height: "200px",
                    margin: "0 auto",
                    borderRadius: "50%",
                    background: "#fcba03",
                  }}
                >
                  <img
                    src={smiles}
                    width="150"
                    height="150"
                    style={{ borderRadius: "50%", marginTop: "13%" }}
                  />
                </div>
                <h1
                  style={{
                    fontSize: "1.5rem",
                    marginTop: "1vh",
                    fontWeight: "900",
                  }}
                >
                  #. 1
                </h1>
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "24px",
                    textAlign: "left",
                  }}
                >
                  For all new clients, first case is free of charge
                </p>
              </div>
              <div
                className="col"
                style={{ marginTop: "5vh", marginBottom: "5vh" }}
              >
                <div
                  style={{
                    width: "200px",
                    height: "200px",
                    margin: "0 auto",
                    borderRadius: "50%",
                    background: "black",
                  }}
                >
                  <img
                    src={services}
                    width="150"
                    height="150"
                    style={{ borderRadius: "50%", marginTop: "13%" }}
                  />
                </div>
                <h1
                  style={{
                    fontSize: "1.5rem",
                    marginTop: "1vh",
                    fontWeight: "900",
                  }}
                >
                  #. 2
                </h1>
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "24px",
                    textAlign: "left",
                  }}
                >
                  For all clients Case pickup and delivery are free
                </p>
              </div>
              <div
                className="col"
                style={{ marginTop: "5vh", marginBottom: "5vh" }}
              >
                <div
                  style={{
                    width: "200px",
                    height: "200px",
                    margin: "0 auto",
                    borderRadius: "50%",
                    background: "#fcba03",
                  }}
                >
                  <img
                    src={office_hours}
                    width="150"
                    height="150"
                    style={{ borderRadius: "50%", marginTop: "13%" }}
                  />
                </div>
                <h1
                  style={{
                    fontSize: "1.5rem",
                    marginTop: "1vh",
                    fontWeight: "900",
                  }}
                >
                  #. 3
                </h1>
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "24px",
                    textAlign: "left",
                  }}
                >
                  All clients will gain personalized LabStar powered access to
                  track, pay, and manage cases
                </p>
              </div>
            </div>
            <div
              className="col-md-12 text-center"
              style={{ marginBottom: "5vh" }}
            ></div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "10vh",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: "1.5rem", fontWeight: "900" }}>
            Have any questions?
          </h1>
          <a href="mailto:Xpress@xcdlab.com">
            <button
              className="btn btn-outline-warning my-2 my-sm-0"
              type="submit"
            >
              Ask Us Now!
            </button>
          </a>
        </div>
      </>
    );
  }
}

export default Benefits;
