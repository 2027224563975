import React, { Component } from "react";
import { Link } from "react-scroll";
import logo from "../images/logo.png";
import "../css/App.css";

class Home_Banner extends Component {
  render() {
    return (
      <div className="home">
        <header>
          <figure className="brand">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "60%", marginTop: "30px", marginLeft: "-45px" }}
            />
          </figure>

          <nav className="menu" style={{ marginTop: "5vh" }}>
            <input type="checkbox" id="menuToggle" />
            <label htmlFor="menuToggle" className="menu-icon">
              <i
                style={{ marginTop: "35px", fontSize: "40px" }}
                className="fa fa-bars"
              ></i>
            </label>
            <ul className="homeNav">
              <li className="banner-links">
                <Link to="about" spy={true} smooth={true}>
                  About
                </Link>
              </li>

              <li className="banner-links">
                <Link to="services" spy={true} smooth={true}>
                  Terms of Services
                </Link>
              </li>
              <li className="banner-links">
                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                >
                  Contact
                </Link>
              </li>

              <li className="banner-links">
                <a style={{ color: "red" }} href="/covid">
                  COVID Protocols
                </a>
              </li>
            </ul>
          </nav>
        </header>
        <div className="container" id="home" style={{ marginTop: "10%" }}>
          <div className="row">
            <div className="col">
              <h1 className="perfectHeading">
                <b>Customizing your perfect smile.</b>
                <br />
                <a href="https://xcdlab.labstar.com/">
                  <button
                    type="button"
                    className="btn btn-warning headingButton"
                    style={{
                      fontWeight: "900",
                      color: "white",
                      border: "1px solid",
                    }}
                  >
                    Resources
                  </button>
                </a>
                {/* <br />{" "}
                <p className="smileHeading"><b>Customizing your perfect smile.</b></p> */}
              </h1>
            </div>
          </div>
          <div className="row" style={{ marginTop: "1vh" }}>
            <div className="col">
              {/* <button type="button" className="btn btn-warning headingButton">
                Book
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home_Banner;
