import React from "react";

import Loader from "react-loader-spinner";
export default class App extends React.Component {
  //other logic
  render() {
    return (
      <div className="page-loader">
        <Loader type="Grid" color="#ffc107" height={100} width={100} />
      </div>
    );
  }
}
