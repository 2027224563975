import React, { Component } from "react";
import { Link } from "react-scroll";
import logo from "../images/logo.png";
import Footer from "./footer";
import Loader from "../helpers/loader";

class Covid_Page extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  }
  render() {
    return (
      <>
        {this.state.loading === false ? (
          <>
            <div style={{ marginBottom: "10vh" }}>
              <div
                className="home"
                style={{
                  height: "60vh",
                  position: "relative",
                  minHeight: "150px",
                }}
              >
                <header>
                  <figure className="brand">
                    <img
                      src={logo}
                      alt="Logo"
                      style={{
                        width: "60%",
                        marginTop: "30px",
                        marginLeft: "-45px",
                      }}
                    />
                  </figure>

                  <nav className="menu" style={{ marginTop: "5vh" }}>
                    <input type="checkbox" id="menuToggle" />
                    <label htmlFor="menuToggle" className="menu-icon">
                      <i
                        style={{ marginTop: "35px", fontSize: "40px" }}
                        className="fa fa-bars"
                      ></i>
                    </label>
                    <ul className="homeNav">
                      <li className="banner-links">
                        <a style={{ color: "white" }} href="/">
                          Home
                        </a>
                      </li>

                      <li className="banner-links">
                        <a style={{ color: "red" }} href="/covid">
                          COVID Protocols
                        </a>
                      </li>
                    </ul>
                  </nav>
                </header>

                <h1
                  className="perfectHeading-covid"
                  style={{ position: "absolute", bottom: "0", left: "0" }}
                >
                  <b>COVID-19 Update.</b>
                </h1>
              </div>
              <p
                className="about-text"
                style={{ marginTop: "5vh", textAlign: "justify" }}
              >
                <b>Updated 05/06/2020</b>
                <br />
                <br />
                On May 6, Governor Hogan and the Maryland Department of Health
                issued new orders that allow dental practices to resume elective
                procedures effective 7 AM on Thursday, May 7, 2020. All
                providers must adhere to the Maryland Department of Health's
                orders before reopening. The Department of Health outlines all
                of the requirements, including many of the procedures that are
                currently in place for emergency procedures - proper hand and
                cough hygiene, social distancing, and especially proper PPE. The
                regulations are rigorous to ensure that the safety of the dental
                team, patient, and community remains paramount. The MSDA will
                continue to share information as new developments arise.
              </p>
              <br />
              <br />
              <h2 style={{ fontSize: "8vh", margin: " 0 15%" }}>
                <b>MSDA OFFICIAL STATEMENT</b>
              </h2>
              <br />

              <p className="about-text" style={{ textAlign: "justify" }}>
                <b>Updated 05/06/2020</b> <br /> <br />
                On May 6, Governor Hogan and the Maryland Department of Health
                issued new orders that allow dental practices to resume elective
                procedures effective 7 AM on Thursday, May 7, 2020. All
                providers must adhere to the Maryland Department of Health's
                orders before reopening. The Department of Health outlines all
                of the requirements, including many of the procedures that are
                currently in place for emergency procedures - proper hand and
                cough hygiene, social distancing, and especially proper PPE. The
                regulations are rigorous to ensure that the safety of the dental
                team, patient, and community remains paramount. The MSDA will
                continue to share information as new developments arise. At
                Xpress Custom Dental Lab Inc. we are taking all measures to
                ensure the safety or all our clients and staff. With continued
                use of PPE and sterilization methods, our goal is to ensure that
                we can maintain our level of work, all while staying safe. For
                more information on COVID-19, and applicable resources, please
                visit the CDC website:{" "}
                <a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html">
                  https://www.cdc.gov/coronavirus/2019-ncov/index.html
                </a>
              </p>
            </div>
            <Footer />
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

export default Covid_Page;
