import React, { Component } from "react";

import "../css/App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import HomeBanner from "./home_banner";
import About from "./about";
import Benefits from "./benefits";
import TermsOfService from "./terms_of_service";
import Footer from "./footer";
import ScrollToTop from "react-scroll-to-top";
import Loader from "../helpers/loader";
import ImageGrid from "../pages/image_grid";
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  }
  render() {
    return (
      <>
        {this.state.loading === false ? (
          <div>
            <ScrollToTop
              smooth
              color="#fff"
              style={{ background: "#ffc107" }}
            />

            <HomeBanner />
            <About />
            <ImageGrid />
            <TermsOfService />
            <Benefits />
            <Footer />
          </div>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}
